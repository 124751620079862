.image-preview-container{
  text-align: center;
  height: 200px;
  width: 100%;
  border: 1px dashed #ececec;
  border-radius: 15px;
  cursor: pointer;
  overflow: hidden;
  :hover{
    border-color: #27306c;
  }
  img {
    height: 100%!important;
    width: auto!important;
    padding: 25px;
    overflow: hidden;
  }
  input[type="file"] {
    display: none;
  }
}
.image-preview-text{
  width: 100%;
  font-size: 35px;
  padding: 80px 20px 0;
  opacity: .35;
  display: none;
}

